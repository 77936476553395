export default function initNavigation() {
    console.log("initNavigation: Running accessibility enhancements.");

    const toggleButtons = document.querySelectorAll('.js-toggle-dropdown');
    const menus = document.querySelectorAll('.dropdown-menu');

    // Close all open submenus
    function closeAllSubmenus() {
        toggleButtons.forEach(btn => {
            btn.setAttribute('aria-expanded', 'false');
        });
        menus.forEach(menu => {
            menu.classList.add('hidden');
            menu.classList.remove('block');
            menu.setAttribute('aria-hidden', 'true');
        });
    }

    // Toggle a specific submenu
    function toggleSubmenu(button, menu) {
  const expanded = button.getAttribute('aria-expanded') === 'true';
  closeAllSubmenus();
  if (!expanded) {
    menu.classList.remove('hidden');
    menu.classList.add('block');
    menu.setAttribute('aria-hidden', 'false');
    button.setAttribute('aria-expanded', 'true');

    // Defer focusing the first menu item slightly
    requestAnimationFrame(() => {
      focusFirstMenuItem(menu);
    });
    
  } else {
    menu.classList.add('hidden');
    menu.classList.remove('block');
    menu.setAttribute('aria-hidden', 'true');
    button.setAttribute('aria-expanded', 'false');
  }
}

    // Focus the first menu item in a submenu
    function focusFirstMenuItem(menu) {
        if (!menu) return;
        const firstItem = menu.querySelector('[role="menuitem"]');
        if (firstItem) {
            firstItem.focus();
        }
    }

    // Focus the last menu item in a submenu
    function focusLastMenuItem(menu) {
        if (!menu) return;
        const items = menu.querySelectorAll('[role="menuitem"]');
        if (items.length > 0) {
            items[items.length - 1].focus();
        }
    }

    // Initialize toggles
    toggleButtons.forEach(button => {
        const submenu = button.nextElementSibling;
        const submenuId = submenu && submenu.id ? submenu.id : `submenu-${Math.random().toString(36).substr(2,9)}`;
        if (submenu && !submenu.id) {
            submenu.id = submenuId;
        }

        // ARIA attributes
        button.setAttribute('aria-haspopup', 'true');
        button.setAttribute('aria-expanded', 'false');
        if (submenu) {
            submenu.setAttribute('aria-hidden', 'true');
            button.setAttribute('aria-controls', submenuId);
        }

        // Click event
        button.addEventListener('click', e => {
            e.preventDefault();
            toggleSubmenu(button, submenu);
        });

        // Keyboard events for the toggle
        button.addEventListener('keydown', e => {
            if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                toggleSubmenu(button, submenu);
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                if (submenu && !submenu.classList.contains('block')) {
                    toggleSubmenu(button, submenu);
                }
                focusFirstMenuItem(submenu);
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                if (submenu && !submenu.classList.contains('block')) {
                    toggleSubmenu(button, submenu);
                }
                focusLastMenuItem(submenu);
            } else if (e.key === 'Escape') {
                e.preventDefault();
                closeAllSubmenus();
                button.focus();
            }
        });
    });

    // Keyboard navigation within submenus
    menus.forEach(menu => {
        const items = menu.querySelectorAll('[role="menuitem"]');
        items.forEach((item, index) => {
            item.addEventListener('keydown', e => {
                const lastIndex = items.length - 1;
                let newIndex;
                switch (e.key) {
                    case 'ArrowDown':
                        e.preventDefault();
                        newIndex = (index + 1) > lastIndex ? 0 : index + 1;
                        items[newIndex].focus();
                        break;
                    case 'ArrowUp':
                        e.preventDefault();
                        newIndex = (index - 1) < 0 ? lastIndex : index - 1;
                        items[newIndex].focus();
                        break;
                    case 'Escape':
                        e.preventDefault();
                        closeAllSubmenus();
                        // Move focus back to the button
                        const toggleButton = document.querySelector(`[aria-controls="${menu.id}"]`);
                        if (toggleButton) toggleButton.focus();
                        break;
                    default:
                        break;
                }
            });
        });
    });

    // Close all on Escape if focus elsewhere
    document.addEventListener('keydown', e => {
        if (e.key === 'Escape') {
            closeAllSubmenus();
        }
    });

    console.log("initNavigation: Setup complete.");
}
