export default function initStackingCards() {
    try {
        const header = document.querySelector('.sticky-header');
        const cards = document.querySelectorAll('.sticky-card');
        const container = document.querySelector('.stacking-cards-container');

        // Early return if required elements aren't found
        if (!header || !cards.length || !container) return;

        function updateScroll() {
            try {
                const headerHeight = header.offsetHeight || 0;
                const viewportHeight = window.innerHeight || 0;
                const lastCard = cards[cards.length - 1];
                if (!lastCard) return;

                const cardTop = headerHeight + 80;

                // Handle header visibility
                const lastCardRect = lastCard.getBoundingClientRect();
                if (lastCardRect.top <= cardTop) {
                    const distancePastTrigger = cardTop - lastCardRect.top;
                    const headerTransform = Math.min(distancePastTrigger, headerHeight);
                    header.style.transform = `translateY(-${headerTransform}px)`;
                } else {
                    header.style.transform = 'translateY(0)';
                }

                // Handle card animations
                cards.forEach((card, index) => {
                    try {
                        if (!card) return;
                        
                        const rect = card.getBoundingClientRect();
                        card.style.top = `${cardTop}px`;

                        if (rect.top <= cardTop) {
                            const nextCard = cards[index + 1];
                            if (nextCard) {
                                const nextRect = nextCard.getBoundingClientRect();
                                const progress = (viewportHeight - nextRect.top) / viewportHeight;
                                const scale = Math.max(0.8, 1 - (progress * 0.2));
                                requestAnimationFrame(() => {
                                    try {
                                        card.style.transform = `scale(${scale})`;
                                    } catch {}
                                });
                            }
                        } else {
                            requestAnimationFrame(() => {
                                try {
                                    card.style.transform = 'scale(1)';
                                } catch {}
                            });
                        }
                    } catch {}
                });
            } catch {}
        }

        let ticking = false;
        window.addEventListener('scroll', () => {
            if (!ticking) {
                requestAnimationFrame(() => {
                    try {
                        updateScroll();
                    } catch {}
                    ticking = false;
                });
                ticking = true;
            }
        });

        window.addEventListener('resize', () => {
            try {
                updateScroll();
            } catch {}
        });

        // Initial load
        updateScroll();
    } catch {}
}