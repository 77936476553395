// index.js

import initContactForm from './contact-us.js';
import initNavigation from './navigation.js';
import initNewsletterSignup from './newsletter-signup.js';
import initPopup from './popup.js';
import initStackingCards from './stacking-card.js';

document.addEventListener('DOMContentLoaded', () => {
    initContactForm();
    initNavigation();
    initNewsletterSignup();
    initPopup();
    initStackingCards();
});
