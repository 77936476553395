export default function initContactForm() {
    // Get the form and return early if not found
    const form = document.getElementById('contact-form');
    if (!form) return;

    // Get required elements with null checks
    const formContent = form.querySelector('.form-content');
    const successMessage = form.querySelector('.success-message');
    const submitButton = form.querySelector('button[type="submit"]');

    if (!formContent || !successMessage || !submitButton) {
        console.warn('Required form elements not found');
        return;
    }

    let captchaLoaded = false;


    // Ensure recaptcha_response input exists
    let recaptchaInput = document.getElementById('recaptcha_response');
    if (!recaptchaInput) {
        recaptchaInput = document.createElement('input');
        recaptchaInput.type = 'hidden';
        recaptchaInput.id = 'recaptcha_response';
        recaptchaInput.name = 'recaptcha_response';
        form.appendChild(recaptchaInput);
    }

    function loadRecaptcha() {
        if (captchaLoaded) return;
        captchaLoaded = true;

        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LcXEcsbAAAAACI79HkuXwvBm-ap6pOItplTXoZ5';
        script.async = true;
        script.defer = true;
        script.onload = () => console.log('reCAPTCHA script loaded');
        document.body.appendChild(script);
    }

    // Attach event listeners to trigger reCAPTCHA loading
    const inputs = form.querySelectorAll('input, textarea');
    inputs.forEach(input => {
        input.addEventListener('focus', loadRecaptcha, { once: true });
    });
    

    // Verify ajax_object exists
    if (typeof ajax_object === 'undefined' || !ajax_object.ajax_url) {
        console.error('ajax_object not properly configured');
        return;
    }

    form.addEventListener('submit', function(e) {
        e.preventDefault();

        // Store original button state
        const originalButtonText = submitButton.textContent;

        // Update button state
        try {
            submitButton.textContent = 'Sending...';
            submitButton.disabled = true;
        } catch (error) {
            console.error('Error updating button state:', error);
        }

        if (!captchaLoaded || typeof grecaptcha === 'undefined') {
            showErrorMessage('Captcha not initialized. Please try again.');
            resetButtonState(originalButtonText);
            return;
        }

        grecaptcha.ready(async function() {
            try {
                // Execute reCAPTCHA
                const token = await grecaptcha.execute('6LcXEcsbAAAAACI79HkuXwvBm-ap6pOItplTXoZ5', { action: 'submit' });
                recaptchaInput.value = token;
                await submitForm();
            } catch (error) {
                console.error('reCAPTCHA error:', error);
                showErrorMessage('An error occurred while verifying reCAPTCHA. Please try again.');
                resetButtonState();
            }
        });
    });

    // Define the submitForm function
    async function submitForm() {
        // Create and populate FormData
        const formData = new FormData(form);
        formData.append('action', 'contact_form_submission');


        // Convert FormData to URLSearchParams
        const data = new URLSearchParams();
        try {
            for (const [key, value] of formData) {
                data.append(key, value);
            }
        } catch (error) {
            console.error('Error converting FormData:', error);
            resetButtonState();
            return;
        }

        // Send the request
        try {
            const response = await fetch(ajax_object.ajax_url, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.success) {
                try {
                    // Fade out form content
                    formContent.style.opacity = '0';
                    formContent.style.transition = 'opacity 0.3s ease';

                    setTimeout(() => {
                        try {
                            // Show and fade in success message
                            successMessage.classList.remove('hidden');
                            successMessage.style.opacity = '0';
                            successMessage.offsetHeight; // Trigger reflow
                            successMessage.style.transition = 'opacity 0.3s ease';
                            successMessage.style.opacity = '1';

                            // Reset form
                            form.reset();
                        } catch (error) {
                            console.error('Error showing success message:', error);
                            showErrorMessage();
                        }
                    }, 300);
                } catch (error) {
                    console.error('Error handling success state:', error);
                    showErrorMessage();
                }
            } else {
                showErrorMessage(responseData.data);
                if (responseData.data?.includes('CAPTCHA')) {
                    grecaptcha.reset();
                }
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorMessage();
        } finally {
            resetButtonState();
        }
    }

    // Helper functions
    function resetButtonState() {
        try {
            submitButton.textContent = originalButtonText;
            submitButton.disabled = false;
        } catch (error) {
            console.error('Error resetting button state:', error);
        }
    }

    function showErrorMessage(message = 'There was an error sending your message. Please try again.') {
        try {
            alert(message);
        } catch (error) {
            console.error('Error showing error message:', error);
        }
    }
}
